<template lang="pug">
  route-transition
    router-view
</template>

<script>
import RouteTransition from "@/components/RouteTransition.vue";
import CompanyQuery from "@/graphql/queries/Company.gql";
export default {
    apollo: {
    company: {
      query: CompanyQuery,
      variables() {
        return { id: this.$route.params.id };
      },
      result() {
        this.$store.commit('setCompany', this.company);
        this.$store.commit('setCompanyUserCount', this.company.userCount);
      }
    },
  },
  components: {
    RouteTransition,
  },
  beforeDestroy() {
    this.$store.commit('setCompany', null);
    this.$store.commit('setCompanyUserCount', 0);
  }
};
</script>
